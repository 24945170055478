$app-icon-archive: '\e900';
$app-icon-arrow-down: '\e93f';
$app-icon-arrow-right: '\e97a';
$app-icon-arrow-up: '\e929';
$app-icon-arrows: '\e936';
$app-icon-boxed-arrow-down: '\e945';
$app-icon-boxed-chevron-left: '\e901';
$app-icon-boxed-chevron-right: '\e902';
$app-icon-boxed-minus: '\e903';
$app-icon-boxed-plus: '\e904';
$app-icon-boxed-times: '\e93d';
$app-icon-bubble: '\e939';
$app-icon-bubbles: '\e963';
$app-icon-calendar: '\e93a';
$app-icon-chart: '\e949';
$app-icon-chart-3: '\e964';
$app-icon-chart-line: '\e994';
$app-icon-checkmark: '\e940';
$app-icon-chevron-down: '\e906';
$app-icon-chevron-down-small: '\e905';
$app-icon-chevron-left: '\e907';
$app-icon-chevron-left-sm: '\e93e';
$app-icon-chevron-right: '\e908';
$app-icon-circle: '\e909';
$app-icon-circled-arrow-right: '\e980';
$app-icon-circled-chevron-left: '\e90a';
$app-icon-circled-chevron-right: '\e90b';
$app-icon-circled-minus: '\e90c';
$app-icon-circled-times: '\e90d';
$app-icon-clock: '\e965';
$app-icon-cocktail: '\e971';
$app-icon-columns: '\e96e';
$app-icon-compare-path1: '\e941';
$app-icon-compare-path2: '\e942';
$app-icon-controls: '\e948';
$app-icon-copy-path1: '\e943';
$app-icon-copy-path2: '\e944';
$app-icon-dollar: '\e98a';
$app-icon-engagement: '\e97b';
$app-icon-enter: '\e94b';
$app-icon-exit: '\e94e';
$app-icon-expand: '\e96a';
$app-icon-external: '\e94c';
$app-icon-eye: '\e94d';
$app-icon-eye-closed: '\e955';
$app-icon-flag: '\e96d';
$app-icon-grid: '\e98d';
$app-icon-handle: '\e97d';
$app-icon-hashtag: '\e97e';
$app-icon-heart: '\e968';
$app-icon-help-path1: '\e97f';
$app-icon-help-path2: '\e983';
$app-icon-images: '\e975';
$app-icon-info: '\e90f';
$app-icon-info-boxed: '\e976';
$app-icon-insights: '\e98e';
$app-icon-label: '\e910';
$app-icon-link: '\e93b';
$app-icon-location: '\e94a';
$app-icon-lock: '\e93c';
$app-icon-logo: '\e911';
$app-icon-menu: '\e912';
$app-icon-message: '\e913';
$app-icon-metrics: '\e977';
$app-icon-mime: '\e94f';
$app-icon-minus: '\e914';
$app-icon-more: '\e915';
$app-icon-nav-comment: '\e950';
$app-icon-nav-contracts: '\e972';
$app-icon-nav-deliverable: '\e967';
$app-icon-nav-document: '\e951';
$app-icon-nav-goals: '\e973';
$app-icon-nav-lock: '\e952';
$app-icon-nav-megaphone: '\e957';
$app-icon-nav-menu: '\e958';
$app-icon-nav-payments: '\e974';
$app-icon-nav-pencil: '\e959';
$app-icon-nav-people: '\e95a';
$app-icon-nav-people-circled: '\e95b';
$app-icon-nav-person: '\e95c';
$app-icon-nav-person-circled: '\e95d';
$app-icon-nav-reporting: '\e95e';
$app-icon-nav-star: '\e95f';
$app-icon-nav-star-circled: '\e960';
$app-icon-nav-survey: '\e961';
$app-icon-nav-widget: '\e962';
$app-icon-person: '\e916';
$app-icon-phone: '\e917';
$app-icon-pin: '\e919';
$app-icon-pin-plus: '\e918';
$app-icon-platforms: '\e984';
$app-icon-plus: '\e91a';
$app-icon-pricing-appearance: '\e91b';
$app-icon-pricing-keynote: '\e91c';
$app-icon-pricing-performance: '\e91d';
$app-icon-range: '\e97c';
$app-icon-repost: '\e969';
$app-icon-rows: '\e96f';
$app-icon-search: '\e91e';
$app-icon-send: '\e966';
$app-icon-social-blog: '\e96b';
$app-icon-social-boxed-blog: '\e96c';
$app-icon-social-boxed-facebook: '\e91f';
$app-icon-social-boxed-instagram: '\e920';
$app-icon-social-boxed-linkedin: '\e90e';
$app-icon-social-boxed-medium: '\e921';
$app-icon-social-boxed-musically: '\e953';
$app-icon-social-boxed-tiktok: '\e98f';
$app-icon-social-boxed-periscope: '\e922';
$app-icon-social-boxed-pinterest: '\e923';
$app-icon-social-boxed-snapchat: '\e924';
$app-icon-social-boxed-soundcloud: '\e925';
$app-icon-social-boxed-tumblr: '\e926';
$app-icon-social-boxed-twitch: '\e970';
$app-icon-social-boxed-twitter: '\e927';
$app-icon-social-boxed-vimeo: '\e928';
$app-icon-social-boxed-youtube: '\e92a';
$app-icon-social-facebook: '\e92b';
$app-icon-social-instagram: '\e92c';
$app-icon-social-linkedin: '\e947';
$app-icon-social-medium: '\e92d';
$app-icon-social-musically: '\e954';
$app-icon-social-tiktok: '\e990';
$app-icon-social-periscope: '\e92e';
$app-icon-social-pinterest: '\e92f';
$app-icon-social-snapchat: '\e930';
$app-icon-social-soundcloud: '\e931';
$app-icon-social-tumblr: '\e932';
$app-icon-social-twitch: '\e933';
$app-icon-social-twitter: '\e934';
$app-icon-social-vimeo: '\e935';
$app-icon-social-youtube: '\e937';
$app-icon-sort-ascending-path1: '\e986';
$app-icon-sort-ascending-path2: '\e987';
$app-icon-sort-descending-path1: '\e988';
$app-icon-sort-descending-path2: '\e989';
$app-icon-speech-bubble: '\e981';
$app-icon-times: '\e938';
$app-icon-trash: '\e946';
$app-icon-triangle-down: '\e956';
$app-icon-triangle-right: '\e98b';
$app-icon-triangle-up: '\e98c';
$app-icon-video: '\e982';
$app-icon-warning: '\e985';
$app-icon-warning-boxed: '\e978';
$app-icon-warning-circled: '\e979';
$app-icon-check-shield: '\e991';
$app-icon-non-vetted: '\e992';
$app-icon-vetted: '\e993';
$app-icon-overlap: '\e995';
$app-icon-store: '\e996';
$app-icon-logo-new: '\e997';
$app-icon-lda: '\e998';
