$font-family: 'Whitney' !default;
$font-location: '../fonts/whitney/';

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 300;
	src: url('#{$font-location}Whitney-Light.eot');
	src: url('#{$font-location}Whitney-Light.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}Whitney-Light.woff2') format('woff2'),
		url('#{$font-location}Whitney-Light.woff') format('woff'),
		url('#{$font-location}Whitney-Light.ttf') format('truetype'),
		url('#{$font-location}Whitney-Light.svg#Whitney-Light') format('svg');
}

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 400;
	src: url('#{$font-location}Whitney-Book.eot');
	src: url('#{$font-location}Whitney-Book.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}Whitney-Book.woff2') format('woff2'),
		url('#{$font-location}Whitney-Book.woff') format('woff'),
		url('#{$font-location}Whitney-Book.ttf') format('truetype'),
		url('#{$font-location}Whitney-Book.svg#Whitney-Book') format('svg');
}

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	src: url('#{$font-location}Whitney-Medium.eot');
	src: url('#{$font-location}Whitney-Medium.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}Whitney-Medium.woff2') format('woff2'),
		url('#{$font-location}Whitney-Medium.woff') format('woff'),
		url('#{$font-location}Whitney-Medium.ttf') format('truetype'),
		url('#{$font-location}Whitney-Medium.svg#Whitney-Medium') format('svg');
}

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	src: url('#{$font-location}WhitneyHTF-SemiBold.eot');
	src: url('#{$font-location}WhitneyHTF-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}WhitneyHTF-SemiBold.woff2') format('woff2'),
		url('#{$font-location}WhitneyHTF-SemiBold.woff') format('woff'),
		url('#{$font-location}WhitneyHTF-SemiBold.ttf') format('truetype'),
		url('#{$font-location}WhitneyHTF-SemiBold.svg#WhitneyHTF-SemiBold') format('svg');
}

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 700;
	src: url('#{$font-location}Whitney-Bold.eot');
	src: url('#{$font-location}Whitney-Bold.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}Whitney-Bold.woff2') format('woff2'),
		url('#{$font-location}Whitney-Bold.woff') format('woff'),
		url('#{$font-location}Whitney-Bold.ttf') format('truetype'),
		url('#{$font-location}Whitney-Bold.svg#Whitney-Bold') format('svg');
}

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 900;
	src: url('#{$font-location}Whitney-Black.eot');
	src: url('#{$font-location}Whitney-Black.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}Whitney-Black.woff2') format('woff2'),
		url('#{$font-location}Whitney-Black.woff') format('woff'),
		url('#{$font-location}Whitney-Black.ttf') format('truetype'),
		url('#{$font-location}Whitney-Black.svg#Whitney-Black') format('svg');
}
