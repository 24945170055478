$font-family: 'BentonSans' !default;
$font-location: '../fonts/bentonsans/';

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 300;
	src: url('#{$font-location}BentonSans-Book.eot');
	src: url('#{$font-location}BentonSans-Book.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}BentonSans-Book.woff2') format('woff2'),
		url('#{$font-location}BentonSans-Book.woff') format('woff'),
		url('#{$font-location}BentonSans-Book.ttf') format('truetype'),
		url('#{$font-location}BentonSans-Book.svg#BentonSans-Book') format('svg');
}

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 400;
	src: url('#{$font-location}BentonSans.eot');
	src: url('#{$font-location}BentonSans.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}BentonSans.woff2') format('woff2'),
		url('#{$font-location}BentonSans.woff') format('woff'),
		url('#{$font-location}BentonSans.ttf') format('truetype'),
		url('#{$font-location}BentonSans.svg#BentonSans') format('svg');
}

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	src: url('#{$font-location}BentonSans-Medium.eot');
	src: url('#{$font-location}BentonSans-Medium.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}BentonSans-Medium.woff2') format('woff2'),
		url('#{$font-location}BentonSans-Medium.woff') format('woff'),
		url('#{$font-location}BentonSans-Medium.ttf') format('truetype'),
		url('#{$font-location}BentonSans-Medium.svg#BentonSans-Medium') format('svg');
}

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	src: url('#{$font-location}BentonSans-Medium.eot');
	src: url('#{$font-location}BentonSans-Medium.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}BentonSans-Medium.woff2') format('woff2'),
		url('#{$font-location}BentonSans-Medium.woff') format('woff'),
		url('#{$font-location}BentonSans-Medium.ttf') format('truetype'),
		url('#{$font-location}BentonSans-Medium.svg#BentonSans-Medium') format('svg');
}

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 700;
	src: url('#{$font-location}BentonSans-Bold.eot');
	src: url('#{$font-location}BentonSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}BentonSans-Bold.woff2') format('woff2'),
		url('#{$font-location}BentonSans-Bold.woff') format('woff'),
		url('#{$font-location}BentonSans-Bold.ttf') format('truetype'),
		url('#{$font-location}BentonSans-Bold.svg#BentonSans-Bold') format('svg');
}

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 900;
	src: url('#{$font-location}BentonSans-Black.eot');
	src: url('#{$font-location}BentonSans-Black.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}BentonSans-Black.woff2') format('woff2'),
		url('#{$font-location}BentonSans-Black.woff') format('woff'),
		url('#{$font-location}BentonSans-Black.ttf') format('truetype'),
		url('#{$font-location}BentonSans-Black.svg#BentonSans-Black') format('svg');
}
