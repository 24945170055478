$font-family: 'Space Mono' !default;
$font-location: '../fonts/spacemono/';

@font-face {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	src: url('#{$font-location}SpaceMono-Regular.eot');
	src: url('#{$font-location}SpaceMono-Regular.eot?#iefix') format('embedded-opentype'),
		url('#{$font-location}SpaceMono-Regular.woff2') format('woff2'),
		url('#{$font-location}SpaceMono-Regular.woff') format('woff'),
		url('#{$font-location}SpaceMono-Regular.ttf') format('truetype'),
		url('#{$font-location}SpaceMono-Regular.svg#SpaceMono-Regular') format('svg');
}
