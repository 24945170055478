$font-family: 'app-sans-serif';
@import 'fonts/whitney';

$font-family: 'app-header';
@import 'fonts/bentonsans';

$font-family: 'app-mono';
@import 'fonts/spacemono';

@import 'fonts/julius';
