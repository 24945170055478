@import 'julius/variables';

$font-location: '../fonts/julius';

@font-face {
	font-family: 'julius-icons';
	font-style: normal;
	font-weight: normal;
	src: url('#{$font-location}/julius-icons.eot?zd4bc3');
	src: url('#{$font-location}/julius-icons.eot?zd4bc3#iefix') format('embedded-opentype'),
		url('#{$font-location}/julius-icons.ttf?zd4bc3') format('truetype'),
		url('#{$font-location}/julius-icons.woff?zd4bc3') format('woff'),
		url('#{$font-location}/julius-icons.svg?zd4bc3#julius-icons') format('svg');
}

.app-icon {
	// use !important to prevent issues with browser extensions that change fonts
	font-family: 'julius-icons' !important;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-transform: none;
	speak: none;

	// Better Font Rendering ===========
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.app-icon-svg svg {
	height: 18px;
}

.app-icon-archive {
	&:before {
		content: $app-icon-archive;
	}
}

.app-icon-arrow-down {
	&:before {
		content: $app-icon-arrow-down;
	}
}

.app-icon-arrow-right {
	&:before {
		content: $app-icon-arrow-right;
	}
}

.app-icon-arrow-up {
	&:before {
		content: $app-icon-arrow-up;
	}
}

.app-icon-arrows {
	&:before {
		content: $app-icon-arrows;
	}
}

.app-icon-boxed-arrow-down {
	&:before {
		content: $app-icon-boxed-arrow-down;
	}
}

.app-icon-boxed-chevron-left {
	&:before {
		content: $app-icon-boxed-chevron-left;
	}
}

.app-icon-boxed-chevron-right {
	&:before {
		content: $app-icon-boxed-chevron-right;
	}
}

.app-icon-boxed-minus {
	&:before {
		content: $app-icon-boxed-minus;
	}
}

.app-icon-boxed-plus {
	&:before {
		content: $app-icon-boxed-plus;
	}
}

.app-icon-boxed-times {
	&:before {
		content: $app-icon-boxed-times;
	}
}

.app-icon-bubble {
	&:before {
		content: $app-icon-bubble;
	}
}

.app-icon-bubbles {
	&:before {
		content: $app-icon-bubbles;
	}
}

.app-icon-calendar {
	&:before {
		content: $app-icon-calendar;
	}
}

.app-icon-chart {
	&:before {
		content: $app-icon-chart;
	}
}

.app-icon-chart-3 {
	&:before {
		content: $app-icon-chart-3;
	}
}

.app-icon-chart-line {
  &:before {
	content: $app-icon-chart-line;
  }
}

.app-icon-checkmark {
	&:before {
		content: $app-icon-checkmark;
	}
}

.app-icon-chevron-down {
	&:before {
		content: $app-icon-chevron-down;
	}
}

.app-icon-chevron-down-small {
	&:before {
		content: $app-icon-chevron-down-small;
	}
}

.app-icon-chevron-left {
	&:before {
		content: $app-icon-chevron-left;
	}
}

.app-icon-chevron-left-sm {
	&:before {
		content: $app-icon-chevron-left-sm;
	}
}

.app-icon-chevron-right {
	&:before {
		content: $app-icon-chevron-right;
	}
}

.app-icon-circle {
	&:before {
		content: $app-icon-circle;
	}
}

.app-icon-circled-arrow-right {
	&:before {
		content: $app-icon-circled-arrow-right;
	}
}

.app-icon-circled-chevron-left {
	&:before {
		content: $app-icon-circled-chevron-left;
	}
}

.app-icon-circled-chevron-right {
	&:before {
		content: $app-icon-circled-chevron-right;
	}
}

.app-icon-circled-minus {
	&:before {
		content: $app-icon-circled-minus;
	}
}

.app-icon-circled-times {
	&:before {
		content: $app-icon-circled-times;
	}
}

.app-icon-clock {
	&:before {
		content: $app-icon-clock;
	}
}

.app-icon-cocktail {
	&:before {
		content: $app-icon-cocktail;
	}
}

.app-icon-columns {
	&:before {
		content: $app-icon-columns;
	}
}

.app-icon-compare {
	.path1:before {
		content: $app-icon-compare-path1;
	}

	.path2:before {
		margin-left: -1.1337890625em;
		content: $app-icon-compare-path2;
		opacity: 0.5;
	}
}

.app-icon-controls {
	&:before {
		content: $app-icon-controls;
	}
}

.app-icon-copy {
	.path1:before {
		content: $app-icon-copy-path1;
	}

	.path2:before {
		margin-left: -1em;
		content: $app-icon-copy-path2;
		opacity: 0.5;
	}
}

.app-icon-dollar {
	&:before {
		content: $app-icon-dollar;
	}
}

.app-icon-engagement {
	&:before {
		content: $app-icon-engagement;
	}
}

.app-icon-enter {
	&:before {
		content: $app-icon-enter;
	}
}

.app-icon-exit {
	&:before {
		content: $app-icon-exit;
	}
}

.app-icon-expand {
	&:before {
		content: $app-icon-expand;
	}
}

.app-icon-external {
	&:before {
		content: $app-icon-external;
	}
}

.app-icon-eye {
	&:before {
		content: $app-icon-eye;
	}
}

.app-icon-eye-closed {
	&:before {
		content: $app-icon-eye-closed;
	}
}

.app-icon-flag {
	&:before {
		content: $app-icon-flag;
	}
}

.app-icon-grid {
	&:before {
		content: $app-icon-grid;
	}
}

.app-icon-handle {
	&:before {
		content: $app-icon-handle;
	}
}

.app-icon-hashtag {
	&:before {
		content: $app-icon-hashtag;
	}
}

.app-icon-heart {
	&:before {
		content: $app-icon-heart;
	}
}

.app-icon-help {
	.path1:before {
		content: $app-icon-help-path1;
		opacity: 0.5;
	}

	.path2:before {
		margin-left: -1.099609375em;
		content: $app-icon-help-path2;
	}
}

.app-icon-images {
	&:before {
		content: $app-icon-images;
	}
}

.app-icon-info, .app-icon-info-circled {
	&:before {
		content: $app-icon-info;
	}
}

.app-icon-info-boxed {
	&:before {
		content: $app-icon-info-boxed;
	}
}

.app-icon-insights {
	&:before {
		content: $app-icon-insights;
	}
}

.app-icon-label {
	&:before {
		content: $app-icon-label;
	}
}

.app-icon-link {
	&:before {
		content: $app-icon-link;
	}
}

.app-icon-location {
	&:before {
		content: $app-icon-location;
	}
}

.app-icon-lock {
	&:before {
		content: $app-icon-lock;
	}
}

.app-icon-logo {
	&:before {
		content: $app-icon-logo;
	}
}

.app-icon-menu {
	&:before {
		content: $app-icon-menu;
	}
}

.app-icon-message {
	&:before {
		content: $app-icon-message;
	}
}

.app-icon-metrics {
	&:before {
		content: $app-icon-metrics;
	}
}

.app-icon-mime {
	&:before {
		content: $app-icon-mime;
	}
}

.app-icon-minus {
	&:before {
		content: $app-icon-minus;
	}
}

.app-icon-more {
	&:before {
		content: $app-icon-more;
	}
}

.app-icon-nav-comment {
	&:before {
		content: $app-icon-nav-comment;
	}
}

.app-icon-nav-contracts {
	&:before {
		content: $app-icon-nav-contracts;
	}
}

.app-icon-nav-deliverable {
	&:before {
		content: $app-icon-nav-deliverable;
	}
}

.app-icon-nav-document {
	&:before {
		content: $app-icon-nav-document;
	}
}

.app-icon-nav-goals {
	&:before {
		content: $app-icon-nav-goals;
	}
}

.app-icon-nav-lock {
	&:before {
		content: $app-icon-nav-lock;
	}
}

.app-icon-nav-megaphone {
	&:before {
		content: $app-icon-nav-megaphone;
	}
}

.app-icon-nav-menu {
	&:before {
		content: $app-icon-nav-menu;
	}
}

.app-icon-nav-payments {
	&:before {
		content: $app-icon-nav-payments;
	}
}

.app-icon-nav-pencil {
	&:before {
		content: $app-icon-nav-pencil;
	}
}

.app-icon-nav-people {
	&:before {
		content: $app-icon-nav-people;
	}
}

.app-icon-nav-people-circled {
	&:before {
		content: $app-icon-nav-people-circled;
	}
}

.app-icon-nav-person {
	&:before {
		content: $app-icon-nav-person;
	}
}

.app-icon-nav-person-circled {
	&:before {
		content: $app-icon-nav-person-circled;
	}
}

.app-icon-nav-reporting {
	&:before {
		content: $app-icon-nav-reporting;
	}
}

.app-icon-nav-star {
	&:before {
		content: $app-icon-nav-star;
	}
}

.app-icon-nav-star-circled {
	&:before {
		content: $app-icon-nav-star-circled;
	}
}

.app-icon-nav-survey {
	&:before {
		content: $app-icon-nav-survey;
	}
}

.app-icon-nav-widget {
	&:before {
		content: $app-icon-nav-widget;
	}
}

.app-icon-person {
	&:before {
		content: $app-icon-person;
	}
}

.app-icon-phone {
	&:before {
		content: $app-icon-phone;
	}
}

.app-icon-pin {
	&:before {
		content: $app-icon-pin;
	}
}

.app-icon-pin-plus {
	&:before {
		content: $app-icon-pin-plus;
	}
}

.app-icon-platforms {
	&:before {
		content: $app-icon-platforms;
	}
}

.app-icon-plus {
	&:before {
		content: $app-icon-plus;
	}
}

.app-icon-pricing-appearance {
	&:before {
		content: $app-icon-pricing-appearance;
	}
}

.app-icon-pricing-keynote {
	&:before {
		content: $app-icon-pricing-keynote;
	}
}

.app-icon-pricing-performance {
	&:before {
		content: $app-icon-pricing-performance;
	}
}

.app-icon-range {
	&:before {
		content: $app-icon-range;
	}
}

.app-icon-repost {
	&:before {
		content: $app-icon-repost;
	}
}

.app-icon-rows {
	&:before {
		content: $app-icon-rows;
	}
}

.app-icon-search {
	&:before {
		content: $app-icon-search;
	}
}

.app-icon-send {
	&:before {
		content: $app-icon-send;
	}
}

.app-icon-social-blog {
	&:before {
		content: $app-icon-social-blog;
	}
}

.app-icon-social-boxed-blog {
	&:before {
		content: $app-icon-social-boxed-blog;
	}
}

.app-icon-social-boxed-facebook {
	&:before {
		content: $app-icon-social-boxed-facebook;
	}
}

.app-icon-social-boxed-instagram {
	&:before {
		content: $app-icon-social-boxed-instagram;
	}
}

.app-icon-social-boxed-linkedin {
	&:before {
		content: $app-icon-social-boxed-linkedin;
	}
}

.app-icon-social-boxed-medium {
	&:before {
		content: $app-icon-social-boxed-medium;
	}
}

.app-icon-social-boxed-musically {
	&:before {
		content: $app-icon-social-boxed-musically;
	}
}

.app-icon-social-boxed-tiktok {
	&:before {
		content: $app-icon-social-boxed-tiktok;
	}
}

.app-icon-social-boxed-periscope {
	&:before {
		content: $app-icon-social-boxed-periscope;
	}
}

.app-icon-social-boxed-pinterest {
	&:before {
		content: $app-icon-social-boxed-pinterest;
	}
}

.app-icon-social-boxed-snapchat {
	&:before {
		content: $app-icon-social-boxed-snapchat;
	}
}

.app-icon-social-boxed-soundcloud {
	&:before {
		content: $app-icon-social-boxed-soundcloud;
	}
}

.app-icon-social-boxed-tumblr {
	&:before {
		content: $app-icon-social-boxed-tumblr;
	}
}

.app-icon-social-boxed-twitch {
	&:before {
		content: $app-icon-social-boxed-twitch;
	}
}

.app-icon-social-boxed-twitter {
	&:before {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 0C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0H4ZM15.6107 4L11.143 9.08172L16 16H12.4265L9.15459 11.3403L5.05857 16H4L8.68517 10.6715L4 4H7.5735L10.6711 8.41146L14.5522 4H15.6107ZM9.21722 10.0653L9.69265 10.7301L12.9255 15.2572H14.5517L10.5889 9.71193L10.1155 9.04718L7.0665 4.78088H5.44032L9.21722 10.0653Z' fill='currentColor'/%3E%3C/svg%3E");
	}
}

.app-icon-social-boxed-vimeo {
	&:before {
		content: $app-icon-social-boxed-vimeo;
	}
}

.app-icon-social-boxed-youtube {
	&:before {
		content: $app-icon-social-boxed-youtube;
	}
}

.app-icon-social-facebook {
	&:before {
		content: $app-icon-social-facebook;
	}
}

.app-icon-social-instagram {
	&:before {
		content: $app-icon-social-instagram;
	}
}

.app-icon-social-linkedin {
	&:before {
		content: $app-icon-social-linkedin;
	}
}

.app-icon-social-medium {
	&:before {
		content: $app-icon-social-medium;
	}
}

.app-icon-social-musically {
	&:before {
		content: $app-icon-social-musically;
	}
}

.app-icon-social-tiktok {
	&:before {
		content: $app-icon-social-tiktok;
	}
}

.app-icon-social-periscope {
	&:before {
		content: $app-icon-social-periscope;
	}
}

.app-icon-social-pinterest {
	&:before {
		content: $app-icon-social-pinterest;
	}
}

.app-icon-social-snapchat {
	&:before {
		content: $app-icon-social-snapchat;
	}
}

.app-icon-social-soundcloud {
	&:before {
		content: $app-icon-social-soundcloud;
	}
}

.app-icon-social-tumblr {
	&:before {
		content: $app-icon-social-tumblr;
	}
}

.app-icon-social-twitch {
	&:before {
		content: $app-icon-social-twitch;
	}
}

.app-icon-social-twitter {
	&:before {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M13.905 10.4695L21.3512 2H19.5869L13.1184 9.35244L7.95583 2H2L9.80863 13.1192L2 22H3.76429L10.591 14.2338L16.0442 22H22L13.905 10.4695ZM11.4877 13.2168L10.6954 12.1089L4.40053 3.30146H7.11084L12.1925 10.412L12.9815 11.5199L19.5861 20.7619H16.8758L11.4877 13.2168Z' fill='currentColor'/%3E%3C/svg%3E");
	}
}

.app-icon-social-vimeo {
	&:before {
		content: $app-icon-social-vimeo;
	}
}

.app-icon-social-youtube {
	&:before {
		content: $app-icon-social-youtube;
	}
}

.app-icon-sort-ascending {
	.path1:before {
		content: $app-icon-sort-ascending-path1;
	}

	.path2:before {
		content: $app-icon-sort-ascending-path2;
    	margin-left: -1.0712890625em;
		opacity: 0.5;
	}
}

.app-icon-sort-descending {
	.path1:before {
		content: $app-icon-sort-descending-path1;
	}

	.path2:before {
		content: $app-icon-sort-descending-path2;
    	margin-left: -1.0712890625em;
		opacity: 0.5;
	}
}

.app-icon-speech-bubble {
	&:before {
		content: $app-icon-speech-bubble;
	}
}

.app-icon-times {
	&:before {
		content: $app-icon-times;
	}
}

.app-icon-trash {
	&:before {
		content: $app-icon-trash;
	}
}

.app-icon-triangle-down {
	&:before {
		content: $app-icon-triangle-down;
	}
}

.app-icon-triangle-right {
	&:before {
		content: $app-icon-triangle-right;
	}
}

.app-icon-triangle-up {
	&:before {
		content: $app-icon-triangle-up;
	}
}

.app-icon-video {
	&:before {
		content: $app-icon-video;
	}
}

.app-icon-warning {
	&:before {
		content: $app-icon-warning;
	}
}

.app-icon-warning-boxed {
	&:before {
		content: $app-icon-warning-boxed;
	}
}

.app-icon-warning-circled {
	&:before {
		content: $app-icon-warning-circled;
	}
}

.app-icon-check-shield {
	&:before {
		content: $app-icon-check-shield;
	}
}

.app-icon-non-vetted {
	&:before {
		content: $app-icon-non-vetted;
	}
}

.app-icon-vetted {
	&:before {
		content: $app-icon-vetted;
	}
}

.app-icon-overlap {
	&:before {
		content: $app-icon-overlap;
	}
}

.app-icon-store {
	&:before {
		content: $app-icon-store;
	}
}

.app-icon-logo-new {
	&:before {
		content: $app-icon-logo-new;
	}
}

.app-icon-lda {
	&:before {
		content: $app-icon-lda;
	}
}

.app-icon-nav-payouts {
	&:before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
		mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.02307 5.62524C2.81638 4.92494 3.85851 4.5 4.99989 4.5H18.4999C19.6413 4.5 20.6834 4.92494 21.4767 5.62524C21.2923 4.14526 20.0298 3 18.4999 3H4.99989C3.46995 3 2.2075 4.14525 2.02307 5.62524Z' fill='currentColor'/%3E%3Cpath d='M2.02307 8.62524C2.81638 7.92494 3.85851 7.5 4.99989 7.5H18.4999C19.6413 7.5 20.6834 7.92494 21.4767 8.62524C21.2923 7.14526 20.0298 6 18.4999 6H4.99989C3.46995 6 2.2075 7.14525 2.02307 8.62524Z' fill='currentColor'/%3E%3Cpath d='M5 9C3.34315 9 2 10.3431 2 12V18C2 19.6569 3.34315 21 5 21H18.5C20.1569 21 21.5 19.6569 21.5 18V12C21.5 10.3431 20.1569 9 18.5 9H14.75C14.3358 9 14 9.33579 14 9.75C14 10.9926 12.9926 12 11.75 12C10.5074 12 9.5 10.9926 9.5 9.75C9.5 9.33579 9.16421 9 8.75 9H5Z' fill='currentColor'/%3E%3C/svg%3E") no-repeat 50% 50%;
    mask-size: cover;
    background-color: currentColor;
	}
}

.app-icon-audience-percentage {
	&:before {
		width: 24px;
		height: 24px;
		display: inline-block;
		mask-size: cover;
		background-color: #00BDDE;
		content: '';
		mask: url("data:image/svg+xml,%3Csvg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.25 0C6.32904 0 4.43374 0.111142 2.57022 0.327409C1.07283 0.501186 0 1.78722 0 3.25699V20.25C0 20.4989 0.123449 20.7315 0.329528 20.8711C0.535608 21.0106 0.797475 21.0388 1.02854 20.9464L4.5 19.5578L7.97146 20.9464C8.15027 21.0179 8.34973 21.0179 8.52854 20.9464L12 19.5578L15.4715 20.9464C15.7025 21.0388 15.9644 21.0106 16.1705 20.8711C16.3766 20.7315 16.5 20.4989 16.5 20.25V3.25699C16.5 1.78722 15.4272 0.501186 13.9298 0.32741C12.0663 0.111142 10.171 0 8.25 0ZM11.7803 7.28033C12.0732 6.98744 12.0732 6.51256 11.7803 6.21967C11.4874 5.92678 11.0126 5.92678 10.7197 6.21967L4.71967 12.2197C4.42678 12.5126 4.42678 12.9874 4.71967 13.2803C5.01256 13.5732 5.48744 13.5732 5.78033 13.2803L11.7803 7.28033ZM4.875 7.5C4.875 6.87868 5.37868 6.375 6 6.375C6.62132 6.375 7.125 6.87868 7.125 7.5C7.125 8.12132 6.62132 8.625 6 8.625C5.37868 8.625 4.875 8.12132 4.875 7.5ZM10.5 10.875C9.87868 10.875 9.375 11.3787 9.375 12C9.375 12.6213 9.87868 13.125 10.5 13.125C11.1213 13.125 11.625 12.6213 11.625 12C11.625 11.3787 11.1213 10.875 10.5 10.875Z' fill='%2300BDDE'/%3E%3C/svg%3E") no-repeat 50% 50%;
	}
}

.app-icon-audience-percentage-inactive {
	&:before {
		width: 24px;
		height: 24px;
		display: inline-block;
		mask-size: cover;
		background-color: #B8BCC4;
		content: '';
		mask: url("data:image/svg+xml,%3Csvg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.25 0C6.32904 0 4.43374 0.111142 2.57022 0.327409C1.07283 0.501186 0 1.78722 0 3.25699V20.25C0 20.4989 0.123449 20.7315 0.329528 20.8711C0.535608 21.0106 0.797475 21.0388 1.02854 20.9464L4.5 19.5578L7.97146 20.9464C8.15027 21.0179 8.34973 21.0179 8.52854 20.9464L12 19.5578L15.4715 20.9464C15.7025 21.0388 15.9644 21.0106 16.1705 20.8711C16.3766 20.7315 16.5 20.4989 16.5 20.25V3.25699C16.5 1.78722 15.4272 0.501186 13.9298 0.32741C12.0663 0.111142 10.171 0 8.25 0ZM11.7803 7.28033C12.0732 6.98744 12.0732 6.51256 11.7803 6.21967C11.4874 5.92678 11.0126 5.92678 10.7197 6.21967L4.71967 12.2197C4.42678 12.5126 4.42678 12.9874 4.71967 13.2803C5.01256 13.5732 5.48744 13.5732 5.78033 13.2803L11.7803 7.28033ZM4.875 7.5C4.875 6.87868 5.37868 6.375 6 6.375C6.62132 6.375 7.125 6.87868 7.125 7.5C7.125 8.12132 6.62132 8.625 6 8.625C5.37868 8.625 4.875 8.12132 4.875 7.5ZM10.5 10.875C9.87868 10.875 9.375 11.3787 9.375 12C9.375 12.6213 9.87868 13.125 10.5 13.125C11.1213 13.125 11.625 12.6213 11.625 12C11.625 11.3787 11.1213 10.875 10.5 10.875Z' fill='%2300BDDE'/%3E%3C/svg%3E") no-repeat 50% 50%;
	}
}

.app-icon-audience-difference {
	&:before {
		width: 24px;
		height: 24px;
		display: inline-block;
		mask-size: cover;
		background-color: #00BDDE;
		content: '';
		mask: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM12.5152 5.23483C12.3687 5.08839 12.1313 5.08839 11.9848 5.23483C11.8384 5.38128 11.8384 5.61872 11.9848 5.76516L13.5947 7.375L7.75 7.375C7.54289 7.375 7.375 7.54289 7.375 7.75C7.375 7.95711 7.54289 8.125 7.75 8.125L13.5947 8.125L11.9848 9.73483C11.8384 9.88128 11.8384 10.1187 11.9848 10.2652C12.1313 10.4116 12.3687 10.4116 12.5152 10.2652L14.7652 8.01517C14.8355 7.94484 14.875 7.84946 14.875 7.75C14.875 7.65054 14.8355 7.55516 14.7652 7.48484L12.5152 5.23483ZM6.40533 11.875L8.01516 10.2652C8.16161 10.1187 8.16161 9.88128 8.01516 9.73483C7.86872 9.58839 7.63128 9.58839 7.48483 9.73483L5.23484 11.9848C5.08839 12.1313 5.08839 12.3687 5.23484 12.5152L7.48484 14.7652C7.63128 14.9116 7.86872 14.9116 8.01517 14.7652C8.16161 14.6187 8.16161 14.3813 8.01517 14.2348L6.40533 12.625H12.25C12.4571 12.625 12.625 12.4571 12.625 12.25C12.625 12.0429 12.4571 11.875 12.25 11.875H6.40533Z' fill='%23B8BCC4'/%3E%3C/svg%3E") no-repeat 50% 50%;
	}
}

.app-icon-audience-difference-inactive {
	&:before {
		width: 24px;
		height: 24px;
		display: inline-block;
		mask-size: cover;
		background-color: #B8BCC4;
		content: '';
		mask: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM12.5152 5.23483C12.3687 5.08839 12.1313 5.08839 11.9848 5.23483C11.8384 5.38128 11.8384 5.61872 11.9848 5.76516L13.5947 7.375L7.75 7.375C7.54289 7.375 7.375 7.54289 7.375 7.75C7.375 7.95711 7.54289 8.125 7.75 8.125L13.5947 8.125L11.9848 9.73483C11.8384 9.88128 11.8384 10.1187 11.9848 10.2652C12.1313 10.4116 12.3687 10.4116 12.5152 10.2652L14.7652 8.01517C14.8355 7.94484 14.875 7.84946 14.875 7.75C14.875 7.65054 14.8355 7.55516 14.7652 7.48484L12.5152 5.23483ZM6.40533 11.875L8.01516 10.2652C8.16161 10.1187 8.16161 9.88128 8.01516 9.73483C7.86872 9.58839 7.63128 9.58839 7.48483 9.73483L5.23484 11.9848C5.08839 12.1313 5.08839 12.3687 5.23484 12.5152L7.48484 14.7652C7.63128 14.9116 7.86872 14.9116 8.01517 14.7652C8.16161 14.6187 8.16161 14.3813 8.01517 14.2348L6.40533 12.625H12.25C12.4571 12.625 12.625 12.4571 12.625 12.25C12.625 12.0429 12.4571 11.875 12.25 11.875H6.40533Z' fill='%23B8BCC4'/%3E%3C/svg%3E") no-repeat 50% 50%;
	}
}
